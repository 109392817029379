import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        statistics: [],
        statisticsFiltered: [],
    },

    actions:{
        fetchAll({ commit }, { materialIds, cityIds, warehouseIds, fromDate, toDate, returnSums }){
            var urlSearchParams = new URLSearchParams()
            var params = { fromDate, toDate, returnSums };
            Object.keys(params).forEach(param => {
                if (params[param] !== null) urlSearchParams.append(param, params[param]);
            })

            materialIds.forEach(materialId => {
                urlSearchParams.append('materialIds', materialId);
            })
            cityIds.forEach(cityId => {
                urlSearchParams.append('cityIds', cityId);
            })
            warehouseIds.forEach(warehouseId => {
                urlSearchParams.append('warehouseIds', warehouseId);
            })
            
            return window.axios.get('/sales', {
                params: urlSearchParams
            })
            .then((response) => {
                commit(types.STORE_STATISTICS, response.data);
                return response.data;
            })
        },

        fetchStatistics({ commit }, { type, fromDate, toDate, startSalesYear, startSalesMonth, endSalesYear, endSalesMonth, startYear, startQuarter, endYear, endQuarter, materialsIds, regionIds, citiesIds, entityId, employeeId }) {
            const urlSearchParams = new URLSearchParams();
            
            // const params = {
            //     FromDate: fromDate,
            //     ToDate: toDate,
            //     FromYearQuarter: {
            //         Year: startYear,
            //         QuarterNo:startQuarter
            //     },
            //     ToYearQuarter: {
            //         Year: endYear,
            //         QuarterNo: endQuarter
            //     }
            // }

            // Object.keys(params).forEach((param) => {
            //    if (params[param] !== null) urlSearchParams.append(param, params[param]);
            // })

            if (fromDate !== null) urlSearchParams.append('FromDate', fromDate);
            if (toDate !== null) urlSearchParams.append('ToDate', toDate);

            if (startSalesYear !== null) urlSearchParams.append('FromMonth.Year', startSalesYear);
            if (startSalesMonth !== null) urlSearchParams.append('FromMonth.Month', startSalesMonth);
            if (endSalesYear !== null) urlSearchParams.append('ToMonth.Year', endSalesYear);
            if (endSalesMonth !== null) urlSearchParams.append('ToMonth.Month', endSalesMonth);
            
            if (startYear !== null) urlSearchParams.append('FromYearQuarter.Year', startYear);
            if (startQuarter !== null) urlSearchParams.append('FromYearQuarter.QuarterNo', startQuarter);
            if (endYear !== null) urlSearchParams.append('ToYearQuarter.Year', endYear);
            if (endQuarter !== null) urlSearchParams.append('ToYearQuarter.QuarterNo', endQuarter);
            if (entityId !== null) urlSearchParams.append('entityId', entityId);
            if (employeeId !== null) urlSearchParams.append('employeeId', employeeId);

            regionIds.forEach(regionId => {
                urlSearchParams.append('RegionIds', regionId);
            })

            switch (type) {
                case 'sales':
                    citiesIds.forEach(cityId => {
                        urlSearchParams.append('CitiesId', cityId);
                    })
                    materialsIds.forEach(materialId => {
                        urlSearchParams.append('MaterialsId', materialId);
                    })

                    return window.axios.get('/sales/statistics', {
                        params: urlSearchParams
                    })
                    .then((response) => response.data)
                case 'visits':
                    return window.axios.get('/visitsStatistics/visits', {
                        params: urlSearchParams
                    })
                    .then((response) => response.data)
                case 'samples':
                    return window.axios.get('/visitsStatistics/materialTransactions', {
                        params: urlSearchParams
                    })
                    .then((response) => response.data)
            }
        },

        fetchStatisticsWarehouse ({ commit }, { regionIds, materialId, fromDate, toDate, accountId, workloadIds, types }) {
            const urlSearchParams = new URLSearchParams();
            var params = { materialId, fromDate, toDate, accountId, types }
            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('workloadIds', id)
            })
            return window.axios.get('/WarehouseStatistics/CustomerReport', { params: urlSearchParams }).then((response) => {
                return response.data  
            })
        },

        fetchStatisticsMaterials({ commit }, { fromDate, toDate, accountId, workloadIds, regionIds, types }) {
            const urlSearchParams = new URLSearchParams();
            var params = { fromDate, toDate, accountId, regionIds, types }
            Object.keys(params).forEach(param => {
                if (params[param] !== null && param[params] !== undefined) urlSearchParams.append(param, params[param])
            });
            regionIds.forEach(id => {
                urlSearchParams.append('regionIds', id)
            })
            workloadIds.forEach(id => {
                urlSearchParams.append('workloadIds', id)
            })
            return window.axios.get('/WarehouseStatistics/MaterialReport', { params: urlSearchParams }).then((response) => {
                return response.data  
            })
        }
    },

    mutations:{
        [types.STORE_STATISTICS](state, statistics){
            state.statistics = statistics;
        }
    },

    getters:{
        getStatisticById: state => id => state.statistics.find(c => id === c.id)
    }
}