<template>
    <div>
        <div class="d-flex">
            <v-btn
                v-if="isSuperAdmin || permissionsUser.ManageTeam === roleName.action"
                @click="dialog = true"
                small
                color="success"
                class="mb-2"
            >
                Add new team
            </v-btn>
            <v-divider v-if="isSuperAdmin || permissionsUser.ManageTeam === roleName.action" class="ms-2 mb-1" vertical />
            <!-- BACK -->
            <v-btn
                color="grey white--text"
                small
                :to="{
                    name: 'staff'
                }"
                :disabled="loading"
                class="mx-2 mb-2"
            >
                <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                Back
            </v-btn>
        </div>
        <!-- delete dialog -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="fetchAll();"
            success-message='Data is deleted'
        >
            Are you sure you want to <span class="red--text">delete</span> this Team?
        </delete-dialog>

        <!-- add and edit dialog -->
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <div class="d-flex justify-end py-1 background-title-card ">
                    <v-card-title
                        class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                    >
                        {{ teamId ? 'Edit team' : 'Add new team' }}
                    </v-card-title>
                    <!-- close button -->
                    <v-btn
                        icon
                        @click="dialog = false; teamId = null"
                    >
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <v-form ref="form" @submit.prevent="submit">
                    <v-card-text class="pb-0 pt-2">
                        <v-row>
                            <v-col cols="6" align-self="center" class="">
                                <v-text-field v-model="name" outlined dense label="Name" hide-details />
                            </v-col>

                            <v-col cols="6" align-self="center" class="">
                                <v-autocomplete
                                    v-model="specialtyIds"
                                    :items="specialties"
                                    item-text="name"
                                    item-value="id"
                                    dense
                                    hide-details
                                    outlined
                                    label="Specialties"
                                    multiple
                                    class="mb-1"
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ specialtyIds.length - 1 }})
                                        </span>
                                    </template>

                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleSpecialty">
                                            <v-list-item-action>
                                                <v-icon color='indigo darken-4'>{{ iconSpecialty }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col v-if="0" cols="12" align-self="center" class="pt-0">
                                <!-- Sector -->
                                <v-autocomplete
                                    label="Areas"
                                    v-model="sectorId"
                                    clearable
                                    :items="sectorsMine"
                                    item-value="id"
                                    item-text="name"
                                    :return-object="false"
                                    :loading="sectorsLoading"
                                    :disabled="sectorsLoading"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    class="mb-1"
                                    @click:clear="cityId = []; regionIds = []"
                                    @change="getCities()"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="6" align-self="center" class="pt-0">
                                <!-- City -->
                                <v-autocomplete
                                    label="City"
                                    v-model="cityId"
                                    :items="sectorId === null ? cities : citiesBySectorFilter"
                                    item-value="id"
                                    item-text="name"
                                    clearable
                                    outlined
                                    dense
                                    hide-details="auto"
                                    class="mb-1"
                                    @click:clear="regionIds = []"
                                    multiple
                                >
                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ cityId.filter((name, index, arr) => arr.indexOf(name) === index).length - 1 }})
                                        </span>
                                    </template>

                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleCitiesDialog">
                                            <v-list-item-action>
                                                <v-icon color='indigo darken-4'>{{ iconCitiesDialog }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="6" align-self="center" class="pt-0">
                                <!-- region -->
                                <v-autocomplete
                                    label="Regions"
                                    v-model="regionIds"
                                    clearable
                                    :items="cityId ? regionsAll.filter(c => cityId.includes(c.cityId)) : regionsAll"
                                    item-value="id"
                                    item-text="name"
                                    :loading="sectorsLoading"
                                    :disabled="sectorsLoading"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    class="mb-1"
                                    multiple
                                >

                                    <template v-slot:selection="{ item, index }">
                                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                        <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                        >
                                            (+{{ regionIds.length - 1 }})
                                        </span>
                                    </template>

                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggleRegion">
                                            <v-list-item-action>
                                                <v-icon color='indigo darken-4'>{{ iconRegions }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>Select All</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider />
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="pt-0">
                        <v-spacer/>
                        <v-btn type="submit" color="success" small>Save</v-btn>
                        <v-btn @click="dialog = false" small text color="alert-color--text" >Cancel</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <bee-handy-table
            :items="teams"
            :headers="headers"
            dense
            zebra
            :loading="loading"
            hide-default-footer
            :height="$vuetify.breakpoint.height - 100"
            :items-per-page="-1"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn @click="teamId = item.id ;dialog = true" color="edit-color" icon>
                    <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                    @click="teamId = item.id; $refs.deleteDialog.activeDeleteDialog(item.id, 'teams')"
                    color="alert-color"
                    icon
                >
                    <v-icon> mdi-delete </v-icon>
                </v-btn>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
import rules from '@/validation rules'
import { roleName } from '@/helpers/enums'
export default {
    components: {
        DeleteDialog
    },
    data() {
        return {
            loading: false,
            teamId: null,
            name: null,
            dialog: false,
            sectorId: null,
            cityId: [],
            regionIds: [],
            sectorsLoading: false,
            rules,
            specialtyIds: [],
            roleName
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                const team = this.getTeamById(this.teamId)
                this.name = team.name
                this.specialtyIds = team.specialtyIds
                this.regionIds = team.regionIds
                this.cityId = team.regionIds.map(c => this.getCityById(this.getRegionAllById(c)?.cityId)?.id)
            } else {
               this.resetAll()
            }
        }
    },

    computed: {
        headers () {
            const headers = [
                {
                    text: 'Name',
                    name: 'name',
                    value: 'name'
                },

                {   
                    text: 'Specialty',
                    name: 'specialty',
                    value: 'specialtyIds',
                    setValueExpr: val => val.map(c => this.getSpecialtyById(c)?.name).join(', ')
                },
                {
                    text: 'City',
                    name: 'city',
                    value: 'regionIds',
                    setValueExpr: val =>
                        val.length > 1
                            ? val.map(c => this.getCityById(this.getRegionAllById(c)?.cityId)?.name)
                                .filter((name, index, arr) => arr.indexOf(name) === index).join(' ,')
                            : this.getCityById(this.getRegionAllById(val[0])?.cityId)?.name,
                }
            ]
            if (this.isSuperAdmin || this.permissionsUser.ManageTeam === roleName.action) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    width: "10%"
                })
            }
            return headers
        },

        ...mapState({
            teams: state => state.teams.teams,
            specialties: state => state.specialties.specialties,
            regionsAll: state => state.regions.regionsAll, 
            cities: state => state.cities.cities,
            citiesBySectorFilter: state => state.sectors.citiesBySectorFilter,
            sectors: state => state.sectors.sectorsMine,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
        }),

        ...mapGetters({
            getSpecialtyById: 'specialties/getSpecialtyById',
            getRegionAllById: 'regions/getRegionAllById',
            getCityById: 'cities/getCityById',
            getTeamById: 'teams/getTeamById'
        }),

        selectedAllRegions () {
            return this.regionIds?.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId))?.length;
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        iconCitiesDialog() {
            if (this.selectedAllCitiesDialog) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllSpecialties () {
            return this.specialtyIds?.length === this.specialties?.length;
        },

        iconSpecialty() {
            if (this.selectedAllSpecialties) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        }
    },

    methods: {
        fetchAll() {
            this.loading = true
            return this.$store.dispatch('teams/fetchAll').finally(() => {
                this.loading = false
            })
        },
        // create team
        submit() {
            this.loading = true
            const moduleName = this.teamId ? 'update' : 'create'
            this.$store.dispatch(`teams/${moduleName}`, { name: this.name, id: this.teamId, regionIds: this.regionIds, specialtyIds: this.specialtyIds }).then(() => {
                this.fetchAll()
            }).finally(() => {
                this.loading = false
                this.dialog = false
            })
        },

        resetAll() {
            this.name = null
            this.regionIds = []
            this.specialtyIds = []
            this.sectorId = null
            this.cityId = null
            this.teamId = null
            this.$refs.form.reset()
        },

        getCities() {
            this.$store.dispatch('sectors/fetchCitiesInSector', { id: this.sectorId })
        },

        toggleRegion() {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id)
                }
            })
        },

        toggleCitiesDialog () {
            this.$nextTick(() => {
                if (this.selectedAllCitiesDialog) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCityDialog()
                }
            })
        },

        fetchRegionsDueCityDialog(){
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

        toggleSpecialty () {
            this.$nextTick(() => {
                if (this.selectedAllSpecialties) {
                    this.specialtyIds = []
                } 
                else {
                    this.specialtyIds = []
                    this.specialtyIds = this.specialties.map(c => c.id);
                    this.specialtyIds.forEach(specialty => {
                    if (this.specialtyIds.includes(specialty.id)) {
                        this.specialtyIds.push(specialty.id);
                    }
                });  
                }
            })
        },
    },

    created() {
        this.loading = true
        Promise.all([
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then(() => {
                if (!this.regionsAll.length) {
                    this.$store.dispatch('regions/fetchAllRegions', {
                        cities: this.cities
                    })
                    .finally(() => {
                        this.loading = false;
                    })
                }
            }) : null,
            this.fetchAll()
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>

<style scoped>

</style>