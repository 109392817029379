<template>
    <div>   
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="needToRestShownItems = true; filter()">
            <p class="mb-0 text-body-1">
                Are you sure you want to <span class="red--text">DELETE</span> the visit ?
            </p>
        </delete-dialog>

        <!-- visit details dialog -->
        <v-dialog
            v-model="dialog"
            transition="dialog-transition"
            max-width="700"
        >
            <!-- dialog content -->
            <v-card>
                <div class="d-flex justify-end background-title-card white--text">
                    <v-card-title
                        class="d-flex w-100 justify-center py-1 d-flex"
                    >
                        {{ $t('Visits.visit-details') }}
                    </v-card-title>
                    <v-btn icon @click="dialog = false; visitId = null; view = false" class="me-2">
                        <v-icon color="white" > mdi-close-circle-outline </v-icon>
                    </v-btn>
                </div>
                <v-card-text class="pb-0 pt-1 px-3">
                    <!-- dialog content -->
                    <!-- samples table -->
                    <div>
                        <p class="text-body-2 font-weight-bold my-1 mb-2">
                            {{ $t('Visits.distributed-materials') }}
                        </p>
                        <bee-handy-smart-table
                            v-model="sampleTableInputs"
                            ref="sampleTable"
                            :items="entriesSamples"
                            :headers="headerSamples"
                            zebra
                            dense
                            height="auto"
                            pagination-on-scroll
                            :show-add-row="!view"
                            :editable-rows="!loading || !view"
                            @keypress.enter="isEditSample ? editSamples() : addSamples()"
                            hide-inputs-details
                            hide-default-footer
                            :data-table-props="{
                                disableSort: true
                            }"
                            :shown-rows-text="$t('shown-rows')"
                        >
                            <!-- product name -->
                            <template v-slot:input.materialId="{ on, attr }">
                                <v-autocomplete
                                    :items="materials"
                                    item-value="id"
                                    item-text="name"
                                    v-on="on"
                                    v-bind="attr"
                                ></v-autocomplete>
                            </template>
                
                            <!-- action column -->
                            <template v-slot:item.actions="{ item, index }">
                                <div class="d-flex">
                                    <tooltip text="Edit doctor" top>
                                        <v-btn
                                            icon
                                            small
                                            color="edit-color"
                                            :disabled="loading"
                                            @click="() => {
                                                $refs.sampleTable.activeEditForRow(index);
                                                editAddressRowIndex = index;
			                                    isEditSample = true;
                                                $refs.sampleTable.resetInputsFocus()
                                            }"
                                        >
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </tooltip>
                                    
                                    <tooltip text="Delete doctor" top>
                                        <v-btn
                                            icon
                                            small
                                            @click="deleteSamplesRow({ id: item.id, index })"
                                            color="alert-color"
                                        >
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </tooltip>
                                </div>
                            </template>
                        </bee-handy-smart-table>
                    </div> 

                    <v-row class="mt-1">
                        <!-- other samples table -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="otherSamples"
                                class="mb-1"
                                label="Other samples"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>

                        <v-divider vertical class="my-1" />

                        <!-- durationInMinutes-->
                        <v-col cols="4">
                            <v-text-field
                                v-model="durationInMinutes"
                                class="mb-1"
                                label="Duration In Minutes"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- coreMessage -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="coreMessage"
                                class="mb-1"
                                label="Core message"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        
                        <v-divider vertical class="my-1" />

                        <!-- Notes -->
                        <v-col cols="4">
                            <v-text-field
                                v-model="notes"
                                class="mb-1"
                                label="Notes"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                        <v-divider vertical class="my-1" />

                        <!-- Objections-->
                        <v-col cols="4">
                            <v-text-field
                                v-model="objections"
                                class="mb-1"
                                label="Objections"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>

                        <!-- notes -->
                        <v-col cols="4" >
                            <v-text-field
                                v-model="nextCall"
                                class="mb-1"
                                label="Next Call"
                                dense
                                outlined
                                hide-details
                                :readonly="view"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <!-- Unavailable octo Products -->
                    <div v-if="(tab !== 1 && tab !==0 ) || (tab === 0 && entityTypeCustomer  !== entityType.doctor)" class="mt-4">
                        <v-autocomplete
                            v-model="unavailablesMaterials"
                            :items="materials"
                            item-value="id"
                            item-text="name"
                            dense
                            outlined
                            hide-details
                            label="Unavailable octo Products"
                            multiple
                            :readonly="view"
                        />
                    </div>

                    <!-- competitive samples  -->
                    <div v-if="(tab !== 1 && tab !==0 ) || (entityTypeCustomer  !== entityType.doctor)" class="my-3">
                        <v-textarea
                        	v-model="competitiveSamples"
                        	label="Competitors"
                        	outlined
                        	dense
                        	hide-details
                        	rows="1"
                        />
                    </div>

                    <!-- center notes -->
                    <div v-if="tab === 3 || (entityTypeCustomer  === entityType.center)">
                        <v-text-field
                            v-model="centerNotes"
                            class="mb-1"
                            label="Center notes"
                            dense
                            outlined
                            hide-details
                        >
                        </v-text-field>
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                    	v-if="!view"
                    	text
                    	color="success"
                    	@click="update()"
                        :loading="submitLoading"
                    >
                        Save
                    </v-btn>
                    <!-- dialog active button -->
                    <v-btn text @click="dialog = false; visitId = null; view = false" :disabled="submitLoading">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
			<v-col>
				<!-- tabs -->
				<v-tabs
					v-model="tab"
					class="d-flex flex-column"
					show-arrows
					slider-color="primary"
					@change="needToRestShownItems = true; clearFilter(); getEntityForType(); filter()"
				>
					<v-tab
						v-for="title in tabs.map(c => c.tabText)"
						:key="title"
						class="text-capitalize"
						:disabled="loading" 
					>
						{{ title }}
					</v-tab>
				</v-tabs>
        		<v-divider class="mx-1" />
			</v-col>

            <v-col align-self="end" class="d-flex" 
				:class="{
					'justify-end': $vuetify.breakpoint.mdAndUp,
					'justify-start': $vuetify.breakpoint.smAndDown
				}"
			>
				<div class="d-flex justify-space-between align-center">            
            		<div class="d-flex">
						<v-btn
							:to="{ name: 'visited-doctors' }"
							small
							color="light-blue white--text"
							class="text-center mx-2"
							elevation="0"
						>
							{{$t('Visits.visits-count')}}
						</v-btn>

                		<!-- View chart -->
						<v-btn
                            v-if="isSuperAdmin || permissionsUser.Charts === roleName.action"
							small
							color="light-blue white--text"
							:to="{ name: 'statistics-chart', params: { type: 'visits' } }"
						>
							<v-icon small class="me-2">mdi-chart-bar</v-icon>
                            {{$t('Visits.view-chart')}}
						</v-btn>

                        <v-divider v-if="excelFile" vertical class="mx-1" />

                        <v-btn
                            v-if="excelFile"
                        	small
                        	color="green lighten-1 white--text"
                        	@click="ImportExcel"
                        	class="mx-1"
                        	:loading="loadingExcel"
                        	:disabled="loadingExcel"
                        >
                            <v-icon> mdi-file-table-outline </v-icon>
                            Export Excel
                        </v-btn>
            		</div>
        		</div>
            </v-col>
        </v-row>

        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; needToRestShownItems = true; filter();">
            <v-row class="align-center ma-1 mt-2">
                <!-- sectors -->
                <v-col v-if="0" md="3" cols="12" class="pa-1 d-flex">
                    <v-autocomplete
                        v-model="sectorId"
                        :items="sectorsMine"
                        item-text="name"
                        item-value="id"
                        label="Areas"
                        dense
                        clearable
                        hide-details
                        :loading="regionsLoading"
                        :disabled="loading || regionsLoading || cityId === []"
                        @click:clear="cityId = []; regionIds = []; fetchAllCities()"
                        @change="getCitiesFilter()"
                        outlined
                    >
                    </v-autocomplete>
                </v-col>

                <!-- city region filter -->
                <v-col lg="4" md="5" sm="8" cols="12" class="pa-1">
                    <v-row class="justify-space-between ma-0">
                        <!-- city name -->
                        <v-col md="5" sm="4" cols="6" class="pa-0 pe-1">
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :label="$t('inputs.city')"
                                dense
                                prepend-icon="mdi-map-marker-multiple"
                                hide-details
                                append-icon
                                multiple
                                clearable
                                :disabled="loading"
                                :search-input.sync="searchCityId"
                                @change="fetchRegionsDueCity; searchCityId = null"
                                outlined
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleCities"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconCities }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('select-all') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ cityId.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
            
                        <!-- region -->
                        <v-col md="7" sm="8" cols="6" class="pa-0">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => cityId.includes(c.cityId)).sort(function(a, b) { if(a.name < b.name) {return -1}; if(a.name > b.name) { return 1 }; return 0 })"
                                item-text="name"
                                item-value="id"
                                :label="$t('inputs.region')"
                                dense
                                multiple
                                clearable
                                hide-details
                                append-icon
                                @change="fetchEntitiesDueRegion"
                                :loading="regionsLoading"
                                :disabled="loading || regionsLoading || cityId === []"
                                outlined
                                :search-input.sync="searchRegion"
                            >
                            
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('select-all') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            
                <v-divider vertical class="my-3 mx-1 grey"/>

                <v-col cols="auto" class="pa-0">
                    <v-radio-group
                        v-model="dateType"
                        row
                        class="mt-1"
                        hide-details
                    >
                        <v-radio
                            label="Daily date"
                            value="1"
                        ></v-radio>
                        <v-radio
                            label="From date / To date"
                            value="2"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col v-if="dateType === '2'" cols="6" sm="3" md="2" class="pa-1">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true
                        }"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col v-if="dateType === '2'" cols="6" sm="3" md="2" class="pa-1">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true
                        }"
                    ></bee-date-picker>
                </v-col>
                <!-- filter -->
                <v-col v-if="dateType === '1'" cols="12" sm="6" md="3" class="pa-1">
                    <bee-date-input
                        v-model="date"
                        append-icon="mdi-menu-right"
                        prepend-icon="mdi-menu-left"
                        :label="$t('inputs.date')"
                        input-format-order="DD-MM-YYYY"
                        dense
                        :loading="loading"
                        hide-details
                        @click:append="
                            needToRestShownItems = true;
                            addDate();
                        "
                        @click:prepend="
                            needToRestShownItems = true;
                            subDate();
                        "
                    ></bee-date-input>
                </v-col>
            
                <v-divider vertical class="my-3 grey"/>
            
                <!-- specialty -->
                <v-col md="2" sm="4" cols="6"  v-if="tab === 0" class="pa-1">
                    <v-autocomplete
                        v-model="specialtyId"
                        :items="specialties"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.specialty')"
                        dense
                        clearable
                        hide-details
                        :loading="filterLoading"
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- classification -->
                <v-col md="2" sm="4" cols="6" class="pa-1">
                    <v-autocomplete
                        class="classification"
                        v-model="workload"
                        :items="classification"
                        item-value="id"
                        item-text="name"
                        :label="$t('inputs.class')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
                
                <v-divider vertical class="my-2 grey"/>
            
                <!-- medical rep name -->
                <v-col md="3" sm="4" cols="12" class="pa-1 d-flex align-start">
                    <v-autocomplete
                        v-model="medicalRepIds"
                        :items="medicalReps.filter(c => c.employeeType === userType.medicalRep)"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.medical-rep')"
                        prepend-icon="mdi-account-tie"
                        hide-details
                        clearable
                        :loading="filterLoading"
                        :disabled="loading"
                        outlined
                        dense
                        multiple
                    >
                        <template v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ medicalRepIds.length - 1 }})
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>
                <!-- customer type 'will appear just for all tap (tab=3)' -->
                <v-col md="2" sm="4" cols="12" class="pa-1" v-if="tab === 0">
                    <v-autocomplete
                        v-model="customerType"
                        :items="customerTypes"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.customer-type')"
                        dense
                        clearable
                        hide-details
                        :loading="filterLoading"
                        :disabled="loading"
                        outlined
                        @change="getEntityForType(customerType)"
                    ></v-autocomplete>
                </v-col>

                <!-- name of -->
                <v-col md="3" sm="4" cols="12" class="pa-1 d-flex align-start">

                    <v-autocomplete
                        ref="autod"
                        v-model="entityId"
                        :items="selections.entities"
                        item-text="name"
                        item-value="id"
                        prepend-icon="mdi-doctor"
                        :label="selections.label[tab]"
                        dense
                        hide-details
                        clearable
                        :loading="entityLoading"
                        :disabled="loading || entityLoading || (tab === 0 && customerType === null)"
                        outlined
                        :multiple="isDoctor"
                    >
                        <template v-if="isDoctor" v-slot:selection="{ item, index }">
                            <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                            <span
                                v-if="index === 1"
                                class="grey--text text-caption"
                            >
                                (+{{ entityId.length - 1 }})
                            </span>
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-divider vertical class="my-3 grey"/>

                <v-col class="d-flex pa-1">
                    <v-btn-toggle
                        v-model="sort"
                        mandatory
                        dense
                        class="mb-1"
                        color="primary"
                        @change="isFiltered = true; needToRestShownItems = true; filter()"
                    >
                        <v-btn :disabled="loading">Sort by name</v-btn>
                        <v-btn :disabled="loading">Sort by classification</v-btn>
                    </v-btn-toggle>
                </v-col>
            
                <v-spacer />
            
                <!-- search button -->
                <v-col cols="auto" class="pa-1">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        small
                        type="submit"
                    >
                        <v-icon class="me-1"> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
            
                     <v-btn
                        class="px-sm-8"
                        @click="needToRestShownItems = true; clearFilter(); filter()"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        {{ $t('reset') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- repeated btn here for responsive regions -->
        <v-btn
            :to="{name: 'visited-doctors'}"
            text
            small
            class="d-md-none d-inline-block mb-5"
        >
            Visited Doctors
        </v-btn>
      
        <bee-handy-table 
            ref="table"
            :headers="headers"
            :items="[]"
            fixed-header
            :height="$vuetify.breakpoint.height - 235"
            :data-table-props="{
                disableSort: true
            }"
            :loading="loading"
            dense
            zebra
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            :shown-rows-text="$t('shown-rows')"
        >           
            <!-- specialty -->
            <template v-slot:item.specialty="{ item }">
                {{ item.entitySpecialtyId.length === 0 ? 
                    item.entitySpecialtyName[0] : 
                    item.entitySpecialtyId.map(c => getSpecialtyById(c).name).join(', ') 
                }}
            </template>
            <!-- actions -->
			<template v-slot:item.action="{ item }">
                <!-- activate button -->
                <tooltip text="View visit" top>
                    <v-btn
                        icon
                        small
                        color="success"
                        :loading="visitId === item.id && view"
                        :disabled="loading || Boolean($route.query.preview)"
                        @click="view = true; visitId = item.id; entityTypeCustomer = item.entityType; fetchVisitData(tabs.map(c => c.id)[tab], item.id);"
                    >
                        <v-icon >mdi-eye</v-icon>
                    </v-btn>
                </tooltip>

                <tooltip text="Edit visit" top>
                    <v-btn
                        icon
                        small
                        color="edit-color"
                        :loading="visitId === item.id && !view"
                        :disabled="loading || Boolean($route.query.preview)"
                        @click="visitId = item.id; entityTypeCustomer = item.entityType; fetchVisitData(tabs.map(c => c.id)[tab], item.id);"
                    >
                        <v-icon >mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>

                <tooltip text="Delete" top>
                    <v-btn
                        icon
                        small
                        color="red lighten-1"
                        @click="$refs.DeleteDialog.activeDeleteDialog(item.id, 'visits')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
			</template>
        </bee-handy-table>
    </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
import DeleteDialog from '@/components/DeleteDialog'
import { mapState, mapGetters } from 'vuex'
import rules from '@/validation rules'
import { entityType, roleName, userType } from '@/helpers/enums'
import { dig, findClassification, downloadBlob } from '@/helpers/functions'
import moment from 'moment'
import { excelFile } from '@/configs/global.config'

export default {
    name: 'Visits',

    components:{
        Tooltip,
        DeleteDialog
    },

    watch: {
        date(val) {
            if (val) {
                this.filter()
            }
        }
    },

    data(){
        return {
            isDoctor: false,
            excelFile,
            submitLoading: false,
            dateType: '1',
            view: false,
            date: moment().format('YYYY-MM-DD'),
            entityTypeCustomer: 1,
            sectorId: null,
            entriesSamples: [],
            materialsEmployee: [],
            isEditSample: false,
            sampleTableInputs: {},
            editAddressRowIndex: null,
            createdAtForVisit: null,
            entityIForRow: null,
            dialog: false,
            searchCityId: null,
            searchRegion: null,
            roleName,
            userType,
            loadingExcel: false,
            entityType,
            tab:0,
            tabs: [
                {
                    id: -1,
                    tabText: 'all',
                    tableText: 'customer'
                },
                {
                    id: entityType.doctor,
                    tabText: 'doctor visits',
                    tableText: 'doctor'
                },
                {
                    id: entityType.pharmacy,
                    tabText: 'pharmacy visits',
                    tableText: 'pharmacy'
                },
                {
                    id: entityType.center,
                    tabText: 'center visits',
                    tableText: 'center'
                }
            ],
            
            // filter data
            medicalRepIds: [],
            cityId: [],
            regionIds: [],
            entityId: [],
            workload:null,
            specialtyId:null,
            startDate: this.moment().startOf('month').format('YYYY-MM-DD'),
            endDate: this.moment().endOf('month').format('YYYY-MM-DD'),
            customerType: null,

            // entity name
            selections:{
                label: ['Customer', 'Doctor', 'Pharmacy', 'Center'],
                entities: [],
            },

            // pagination props
            options: {},
            totalVisits: null,

            // helpers 
            visitId: null,
            loading: false,
            filterLoading: false,
            entityLoading: false,
            regionsLoading: false,
            isFiltered: false,

            customerTypes: [
                { id: entityType.center, name: 'centers' },
                { id: entityType.doctor, name: 'doctors' },
                { id: entityType.pharmacy, name: 'pharmacies' }
            ],

            // visit dialog
            samples: [],
            otherSamples: null,
            notes: null,
            objections: null,
            coreMessage: null,
            durationInMinutes: null,
            nextCall: null,
            unavailablesMaterials: [],
            competitiveSamples: [],
            centerNotes: null,

            // pagination
            needToRestShownItems: false,
            scrollOptions: {},
            serverItemsLength: 0,

            rules,
            sort: 1,
        }
    },

    computed:{
        ...mapState({
            visits: state => state.visits.visits,
            visitsFiltered: state => state.visits.visitsFiltered,
            cities: state => state.cities.cities,
            materials: state => state.materials.materials,
            regions: state => state.regions.regions,
            regionsAll: state => state.regions.regionsAll,
            medicalReps: state => state.medicalreps.medicalreps,
            specialties: state => state.specialties.specialties,
            classification: state => state.workload.classification,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            sectorsMine: state => state.sectors.sectorsMine
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            getRegionAllById: 'regions/getRegionAllById',
            getCityById: 'cities/getCityById',
            getMaterialById: 'materials/getMaterialById',
            getEntityById: 'entities/getEntityById',
            getVisitById: 'visits/getVisitById',
            getCityByName: 'cities/getCityByName',
            getRegionAllByName: 'regions/getRegionAllByName',
            getSupervisorById: 'supervisors/getSupervisorById',
            getClassificationById: 'workload/getClassificationById',
        }),
        
        isAllFieldsEmpty(){
            return (
                !this.medicalRepIds && this.regionIds.length === 0 && !this.entityId && !this.startDate && !this.endDate && this.customerType === null && !this.specialtyId && this.workload === null 
            )
        },
        
        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

        icon() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        headers(){
            const headers = [
                { 
                    text: 'Employee name',
                    value: 'employeeId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name,
                    class: 'primary--text',
                    cellClass: 'primary--text',
                    align: 'start',
                },
                {
                    text: 'Date', 
                    value: 'createdAt', 
                    type: 'date',
                    dateFormat: 'YYYY-MM-DD',
                    align: 'start',  
                    cellClass:'text-caption', 
                    columnClass: 'font-sm',
                },
                { 
                    text: this.tabs.map(c => c.tableText)[this.tab],
                    value: 'entityName',
                    align: 'start',
                    class:'text-capitalize text-body-2 font-weight-bold',
                },
                { 
                    text: 'City', 
                    value: 'entityCityName', 
                    align: 'start',
                },
                { 
                    text: 'Region', 
                    value: 'entityRegionName', 
                    align: 'start',
                },
                { 
                    text: 'Notes', 
                    value: 'notes', 
                    // setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'center', 
                    noNotAvailableText: true
                },
                {
                    text: 'Core message',
                    value: 'coreMessage',
                    align: 'center'
                },
                { 
                    text: 'Objections', 
                    value: 'objections', 
                    setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'start', 
                    noNotAvailableText: true
                },
                { 
                    text: 'Next Call', 
                    value: 'purpose', 
                    setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'start',
                    noNotAvailableText: true
                },
                { 
                    text: 'Action', 
                    name: 'action', 
                    value: 'action',
                    align: 'center', 
                    width: '10%'
                },
            ];

            if (this.tab !== 1) {
                headers.splice(2, 0,
                    {
                        text: 'Specialty', 
                        value: 'entitySpecialtyName',
                        name: 'specialty',
                        // setValueExpr: val => val.map(c => this.dig('getSpecialtyById', c, 'name')).join(', '),
                        // setValueExpr: (val, item) => item,
                        align: 'start',
                        noNotAvailableText: true
                    },
                    { 
                        text: 'Class', 
                        value: 'entityWorkload', 
                        setValueExpr: val => this.getClassificationById(val)?.name || '-',
                        align: 'start'
                    }
                )
            } else {
                headers.splice(headers.length - 1, 0, {
                    text: 'Unavailable Products',
                    value: 'unavailableRamaProductsCount',
                    align: 'start',
                })
            }
            
            const sharedClass = 'text-body-2 font-weight-bold px-2';

            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;                
                headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';                
            }

            return headers;
        },

        headerUnAvailableSamples () {
            return [
                {
                    text: 'Name',
                    name: 'name',
                    value: 'material',
                    setValueExpr: val => this.getMaterialById(val)?.name
                }
            ]
        },

        headerSamples() {
            const headers = [
                {
                    text: 'Samples',
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: 'quantity',
                    name: 'amount',
                    value: 'amount',
                    type: 'number'
                }
            ];
            if (!this.view) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                })
            } 
            return headers
        },

        entityTypeForCurrentTab(){
            switch (this.tab) {
                case 1: return entityType.doctor;
                case 2: return entityType.pharmacy;
                case 3: return entityType.center;
                case 0: return null;
                default: return null;
            }
        },

        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },
        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        deleteSamplesRow(rowData) {
            this.$refs.sampleTable.resetEdit();
			this.isEditSample = false;
			this.entriesSamples.splice(rowData.index, 1);

            for (let i = 0; i < this.entriesSamples.length; i++) {
                if (this.entriesSamples[i].id > rowData.id) {
                    this.entriesSamples[i].id--;
                }
            }
			
			this.$nextTick(() => {
				this.$refs.sampleTableInputs.resetInputs();
				this.$refs.sampleTableInputs.resetInputsFocus()
			})
		},
        
        delete() {
            this.loading = true
            this.$store.dispatch('visits/deleteVisit', { id: this.visitId }).then(() => {
                this.filter()
            }).finally(() => {
                this.loading = false
            })
        },

        editSamples() {
            if (this.$refs.sampleTable.validate()) {
                this.entriesSamples[this.editAddressRowIndex].materialId = this.sampleTableInputs.materialId
                this.entriesSamples[this.editAddressRowIndex].amount = this.sampleTableInputs.amount
                
                this.$refs.sampleTable.resetEdit();
                this.isEditSample = false;
                this.$nextTick(() => {
                    this.$refs.sampleTable.resetInputs();
					this.$refs.sampleTable.resetInputsFocus()
                })
			} 
        },

        addSamples() {
            if (this.$refs.sampleTable.validate()) {
                const itemInTable = this.entriesSamples.find(c => c.materialId === this.sampleTableInputs.materialId);
                if (itemInTable) {
                    itemInTable.amount = itemInTable.amount + 1
                } else {
                    this.entriesSamples.push({
                        ...this.sampleTableInputs,
                    })
                    this.entriesSamples.map((c, index) => ({ ...c, id: index }))
                }
                this.$refs.sampleTable.resetInputs();
                this.$refs.sampleTable.resetInputsFocus();
            }
        },
        ImportExcel() {
            this.loadingExcel = true
            this.$store.dispatch('exporter/fetchVisitsExport', { 
                FromDate: this.startDate, 
                ToDate: this.endDate, 
                EntityType: this.tab === 1 || this.tab === 2 ? this.tab : this.tab === 3 ? 0 : null,
                RegionIds: this.regionIds,
                EmployeeId: this.medicalRepIds,
                SpecialtyId: this.specialtyId,
                Workload: this.workload,
                OrderByWorkLoad: this.sort,
                OrderByName: this.sort
            }).finally(() => {
                this.loadingExcel = false
            })
        },
        // helpers
        dig,
        findClassification,
        moment,

        update() {
            this.needToRestShownItems = true
            this.submitLoading = true
            this.$store.dispatch('visits/updateVisit', {
                id: this.visitId,
                entityId: this.entityIForRow, 
                otherSamples: this.otherSamples, 
                objections: this.objections,
                nextVisitNotes: this.centerNotes,
                purpose: this.nextCall, 
                materialAmounts: this.entriesSamples.map(c => ({ materialId: c.materialId, amount: c.amount })),
                unavailableMaterialIds: this.unavailablesMaterials, 
                competitiveSamples: [this.competitiveSamples], 
                createdAt: this.createdAtForVisit,
                coreMessage: this.coreMessage, 
                durationInMinutes: this.durationInMinutes,
                notes: this.notes
           }).then(() => {
                this.filter()
                this.dialog = false
                this.view = false
           }).finally(() => {
                this.visitId = null
                this.submitLoading = false
           })
        },

        // Find data functions 
        fetchVisitData(type, visitId){
            this.$store.dispatch('visits/fetchById', { id: visitId })
            .then((data) => {
                this.entriesSamples = data.materialAmounts.map((c, index) => ({ ...c, id: index }));
                this.otherSamples = data.otherSamples;
                this.notes = data.notes;
                this.objections = data.objections;
                this.nextCall = data.purpose;
                this.coreMessage = data.coreMessage;
                this.durationInMinutes = data.durationInMinutes;

                this.unavailablesMaterials = data.unavailableMaterialIds;
                this.competitiveSamples = data.competitiveSamples.join(', ');
                this.centerNotes = null;
                
                this.dialog = true;
                this.entityIForRow = data.entityId
                this.createdAtForVisit = data.createdAt

                if (type === entityType.pharmacy || this.tab === 0) {
                    // data.unavailableMaterialIds.forEach((element) => {
                    //     this.unavailablesMaterials.push(this.getMaterialById(element))
                    // });
                    // this.competitiveSamples = data.competitiveSamples;
                } else if (type === entityType.center || this.tab === 0) {
                    this.centerNotes = data.nextVisitNotes;
                }
                this.$store.dispatch('materialTransactions/fetchRemaining', { id: data.employeeId }).then((data) => {
                    this.materialsEmployee = data
                })
            })
        },

        fetchRegionsDueCity(){
            this.entityId = [];
            this.selections.entities = [];
            this.regionIds = [];
            if (!this.cityId) return;

            this.regionIds = [];
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });

            this.selections.entities = [];
            if (this.regionIds.length === 0) return;

            this.entityLoading = true;
            this.getEntityForType()
        },

        fetchEntitiesDueRegion(){
            this.selections.entities = [];
            if (this.regionIds.length === 0) return;

            this.entityLoading = true;
            this.$store.dispatch('entities/fetchAllBrief', {
                type: this.entityTypeForCurrentTab,
                regionIds: []
            })
            .then((response) => {
                this.selections.entities = response.data;
            })
            .finally(() => {
                this.entityLoading = false
            })
            this.searchRegion = null
        },
        
        filter(newOptionsScroll, isFirstLoad = false) {
            this.loading = true;
            let page = 1;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

            const { itemsPerPage } = this.scrollOptions;
            return this.$store.dispatch('visits/fetchByFilter', {
                fromDate: this.dateType === '1' ? this.date : this.startDate, 
                toDate: this.dateType === '1' ? this.date : this.endDate,
                /*
                    next line is testing 'customerType' !== null that's mean filter -of all tab- don't apply so entityType belongs to current tab by 'entityTypeForCurrentTab'
                */
                entityType: this.customerType !== null ? this.customerType : this.entityTypeForCurrentTab,
                regionIds: this.regionIds,
                employeeIds: this.medicalRepIds,
                // entityName: this.entityId,
                entityIds: this.entityId.length !== 0 ? this.customerType === entityType.doctor ? this.entityId : [this.entityId] : [],
                specialtyId: this.specialtyId, 
                workload: this.workload,
                page: page,
                PerPage: itemsPerPage || 20,
                OrderByName: this.sort === 0,
                OrderByWorkLoad: this.sort === 1,
                IsMedicalRep: true
            })
            .then(({ data, total }) => {
                if (this.needToRestShownItems) {
                    this.$refs.table.resetShownRows();
                    this.needToRestShownItems = false;
                }
                this.totalVisits = total;
                this.$refs.table.addScrollItems(data);
                this.serverItemsLength = this.totalVisits;
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        clearFilter(){
            // clear fields
            this.medicalRepIds = [];
            this.cityId = [];
            this.regionIds = [];
            this.entityId = [];
            this.startDate = this.moment().format('YYYY-MM-DD');
            this.endDate = this.moment().format('YYYY-MM-DD');
            this.customerType = null;
            this.specialtyId = null;
            this.workload = null
            this.visit = null;

            // reset total visites
            this.totalVisits = null;

            this.isFiltered = false
        },

        toggle () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        // select all inputs
        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCity()
                }
            })
        },

        getEntityForType(type) {
            this.entityId = []
            if (type === entityType.doctor) {
                this.isDoctor = true
            } else {
                this.isDoctor = false
            }
            this.entityLoading = true;
            this.$store.dispatch('entities/fetchAllBrief', { 
                type: this.entityTypeForCurrentTab === null ? type : this.entityTypeForCurrentTab,
                regionIds: []
            }).then((data) => {
                console.log(data)
                this.selections.entities = data;
             }).finally(() => {
                this.entityLoading = false
            })
        },

        getCitiesFilter() {
            if (this.sectorId !== null) {
                this.loading = true;
                this.$store.dispatch('sectors/fetchCitiesInSector', { id: this.sectorId }).finally(() => {
                    this.loading = false
                })
            }
        },

        fetchAllCities() {
            this.sectorId = null
            this.loading = true
            this.$store.dispatch('cities/fetchCityMine').finally(() => {
                this.loading = false
            })
        },

        addDate () {
            this.date = moment(this.date).add(1, 'day').format('YYYY-MM-DD');
            return this.date
        },

        subDate() {
            this.date = moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
            return this.date
        },
    },

    created() {
        this.loading = true;
        this.filterLoading = true;

        Promise.all([
            this.$store.dispatch('workload/fetchAll'),
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
            !this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {}) : null,
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.sectorsMine.length ? this.$store.dispatch('sectors/getSectorsMine') : null,
            this.getEntityForType(entityType.doctor),
            (async () => {
                if (!this.medicalReps.length) {
                    await this.$store.dispatch('medicalreps/fetchAll', { nonDeleted: true });
                }
                await this.filter(null, true);
            })()
        ]).finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },
    
    metaInfo: {
        title: 'Visits',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style scoped>
.v-expansion-panel-content__wrap{
    padding: 0px 13px 0px;
}
#samples-table thead > tr > th{
    font-size: .9rem;
}
.font-sm {
    font-size: 9px;
}
.w-100 {
    width: 100%;
    margin-left: 1rem;
}
</style>
